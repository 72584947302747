<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group :label-for="id">
      <template #label>
        <span
          :class="{required:rules.includes('required')}"
        >
          {{ label }}
        </span>
      </template>

      <v-select
        :id="id"
        v-model="selectedProxy"
        v-b-tooltip.hover
        :options="options"
        :title="tooltipTitle"
        :reduce="valueReducer"
        :placeholder="placeholder"
        :label="textField"
        :multiple="$attrs.multiple"
        :close-on-select="closeOnSelect"
        :disabled="disabled"
        @search="search"
      />
      <b-form-invalid-feedback
        :state="getValidationState(validationContext)"
      >
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@/@core/comp-functions/forms/form-validation'

export default {
  name: 'CustomVueSelect',
  components: { vSelect },
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    rules: { type: String, default: '' },
    selected: { type: [Number, String, Array, Object, Boolean], default: '' },
    options: { type: Array, default: () => [] },
    textField: { type: String, default: '' },
    valueReducer: { type: Function, default: option => option.id },
    dataProvider: { type: Function, default: () => {} },
    closeOnSelect: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    minSearchChars: { type: Number, default: 3 },
  },
  computed: {
    selectedProxy: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit('update:selected', value)
      },
    },
    tooltipTitle() {
      return !this.disabled ? this.placeholder : ''
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
  methods: {
    search(title) {
      if (title === '' || title === undefined || title.length < this.minSearchChars) {
        return
      }
      this.dataProvider(title)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
::placeholder {
  color:rgba(128, 128, 128, 0.693) !important;
}
</style>
